import { env } from "@saas/config/shared";
import { FlagKeys } from "@saas/flags/utils";
import {
  FinancialReportIcon,
  HelpIcon,
  InventoryIcon,
  MarketplaceIcon,
  MelakaMarketIcon,
  ShopIcon,
  SVG,
} from "@saas/shared/icon";

export type MenuItem = {
  icon?: SVG;
  label: string;
  url: string;
  testid?: string;
};

export type MenuHeader = {
  label: string;
  testid?: string;
  url?: string;
  enabled?: boolean;
  child?: MenuItem[];
};

export type GetMenuItemsInput = Record<FlagKeys, boolean>;

export type GetMenuItemsOutput = MenuHeader[];

export const getMenuItems = (flags?: GetMenuItemsInput): GetMenuItemsOutput => {
  return [
    {
      label: "Solusi Kami",
      testid: "landing__menu__our_solutions",
      enabled: true,
      child: [
        {
          icon: MarketplaceIcon,
          label: "Integrasi Marketplace",
          url: env.LANDING_URL + "#marketplace-integration",
          testid: "landing__dropdown-menu__marketplace-integration",
        },
        {
          icon: InventoryIcon,
          label: "Manajemen Inventori",
          url: env.LANDING_URL + "#store-management",
          testid: "landing__dropdown-menu__inventory-management",
        },
        {
          icon: ShopIcon,
          label: "Manajemen Order",
          url: env.LANDING_URL + "#store-management",
          testid: "landing__dropdown-menu__order-management",
        },
        {
          icon: FinancialReportIcon,
          label: "Catatan Keuangan",
          url: env.LANDING_URL + "#accounting",
          testid: "landing__dropdown-menu__accounting",
        },
        {
          icon: MarketplaceIcon,
          label: "Analisa Pelanggan",
          url: env.LANDING_URL + "#promotion",
          testid: "landing__dropdown-menu__customer-analytics",
        },
        ...[],
        {
          icon: MelakaMarketIcon,
          label: "Melaka Market",
          url: env.RESELLER_URL,
          testid: "landing__dropdown-menu__melaka_market",
        },
      ],
    },
    {
      label: "Edukasi",
      testid: "landing__menu__education",
      enabled: true,
      child: [
        {
          icon: InventoryIcon,
          label: "Seller Academy",
          url: env.ACADEMY_URL,
          testid: "landing__dropdown-menu__learning-centre",
        },
        {
          icon: HelpIcon,
          label: "Pusat Bantuan",
          url: env.HELP_URL,
          testid: "landing__dropdown-menu__help-center",
        },
      ],
    },
  ];
};

export default getMenuItems;
