import { env } from "@saas/config/shared";
import { MetaPropertiesInterface } from "@saas/shared/utils";

export type MetaPropertiesProps = {
  properties: MetaPropertiesInterface;
};

export const MetaProperties = ({ properties }: MetaPropertiesProps) => {
  const {
    locale = "id_ID",
    url = env.APP_URL,
    title = "Melaka - Solusi Pengelolaan Toko Online Terlengkap",
    type = "website",
    description = "Melaka menggabungkan semua kebutuhan penjualan online termasuk menghubungkan ke toko online, manajemen stok, manajemen promosi, evaluasi kinerja, manajemen pesanan, serta interaksi pelanggan.",
    image = {
      type: "image/png",
      url: env.APP_URL + "/images/meta/og-thumbnail.png",
      alt: "Melaka Logo",
    },
    card = "summary_large_image",
  } = properties;
  return (
    <>
      <meta name={"application-name"} content={"Melaka App"} />

      <meta name={"apple-mobile-web-app-capable"} content={"yes"} />
      <meta
        name={"apple-mobile-web-app-status-bar-style"}
        content={"default"}
      />
      <meta name={"apple-mobile-web-app-title"} content={"Melaka"} />

      <meta name={"msapplication-TileColor"} content={"#ffffff"} />

      <meta name={"format-detection"} content={"telephone=no"} />
      <meta name={"mobile-web-app-capable"} content={"yes"} />
      <meta name={"msapplication-tap-highlight"} content={"no"} />

      <meta name={"theme-color"} content={"#ffffff"} />
      <meta name={"description"} content={description} />

      <meta property={"og:locale"} content={locale} />
      <meta property={"og:title"} content={title} />
      <meta property={"og:type"} content={type} />
      <meta property={"og:description"} content={description} />
      <meta property={"og:url"} content={url} />
      <meta property={"og:site_name"} content={"Melaka"} />
      <meta property={"og:image"} content={image.url} />
      <meta
        property={"og:image:secure_url"}
        content={image.url.replace("http://", "https://")}
      />
      <meta property={"og:image:width"} content={image.width || "1200"} />
      <meta property={"og:image:height"} content={image.height || "630"} />
      <meta property={"og:image:alt"} content={image.alt} />
      <meta property={"og:image:type"} content={image.type} />

      <meta name={"twitter:image"} content={image.url} />
      <meta name={"twitter:card"} content={card} />
      <meta name={"twitter:url"} content={url} />
      <meta name={"twitter:title"} content={title} />
      <meta name={"twitter:description"} content={description} />
    </>
  );
};

export default MetaProperties;
