export interface ManifestLinksInterface {
  href: string;
  size?: string;
  rel?: string;
  media?: string;
  type?: string;
  color?: string;
}

export const manifestLinks: ManifestLinksInterface[] = [
  { rel: "manifest", href: "/site.webmanifest" },
  { rel: "shortcut icon", href: "/favicon.ico" },
  { rel: "icon", href: "/favicon.svg", type: "image/svg+xml" },
  { rel: "mask-icon", href: "/favicon.svg", color: "#ffffff" },
  { rel: "mask-icon", href: "/favicon.svg", color: "#ffffff" },
  {
    rel: "apple-touch-icon",
    href: "/images/meta/touch-icon-ipad.png",
    size: "152x152",
  },
  {
    rel: "apple-touch-icon",
    href: "/images/meta/touch-icon-iphone-retina.png",
    size: "180x180",
  },
  {
    rel: "apple-touch-icon",
    href: "/images/meta/touch-icon-ipad-retina.png",
    size: "167x167",
  },
  {
    rel: "apple-touch-icon",
    href: "/images/meta/icon-192x192.png",
    size: "192x192",
  },
  {
    rel: "apple-touch-icon",
    href: "/images/meta/icon-512x512.png",
    size: "512x512",
  },
  {
    rel: "apple-touch-startup-image",
    href: "/images/meta/apple-splash-2048.png",
    size: "2048x2732",
    media:
      "(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)",
  },
  {
    rel: "apple-touch-startup-image",
    href: "/images/meta/apple-splash-1668.png",
    size: "1668x2224",
    media:
      "(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)",
  },
  {
    rel: "apple-touch-startup-image",
    href: "/images/meta/apple-splash-1536.png",
    size: "1536x2048",
    media:
      "(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)",
  },
  {
    rel: "apple-touch-startup-image",
    href: "/images/meta/apple-splash-1125.png",
    size: "1125x2436",
    media:
      "(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)",
  },
  {
    rel: "apple-touch-startup-image",
    href: "/images/meta/apple-splash-1242.png",
    size: "1242x2208",
    media:
      "(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)",
  },
  {
    rel: "apple-touch-startup-image",
    href: "/images/meta/apple-splash-750.png",
    size: "750x1334",
    media:
      "(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)",
  },
  {
    rel: "apple-touch-startup-image",
    href: "/images/meta/apple-splash-640.png",
    size: "640x1136",
    media:
      "(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)",
  },
];

export default manifestLinks;
