import { FC, ReactNode } from "react";

import { Logo } from "@saas/shared/ui";

export interface SidebarMenuProps {
  children: ReactNode;
}

export const SidebarMenu: FC<SidebarMenuProps> = ({ children }) => {
  return (
    <div className={`h-full bg-white px-5 py-6`}>
      <Logo />
      {children}
    </div>
  );
};

SidebarMenu.displayName = "SidebarMenu";

export default SidebarMenu;
