import { ampli } from "@saas/core";

export const trackClickHeaderMenu = (menuName: string) => {
  const { location } = window;

  ampli.clickHeaderMenu({
    menu_name: menuName,
    page_domain: location.origin,
    page_location: location.href,
    page_path: location.pathname,
    page_title: "Melaka",
    page_url: location.href,
  });
};
